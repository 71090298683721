<template>
  <div class="mainPage">
    <PageHeader title="子账户管理" />
    <div class="searchWrap">
      <Row :gutter="16">
        <Col span="24" class="searchCol">
          <span>账号 :</span>
          <Input
            v-model="account"
            placeholder="请输入子账户账号"
            class="searchIpt"
          >
          </Input>
          <Button class="saveBtn" @click="loadData" style="margin-left: 20px"
            >查询</Button
          >
          <Button
            :loading="loading"
            class="saveBtn"
            @click="addChildUser"
            style="margin-left: 20px"
            >新增子账号</Button
          >
          <Button
            :loading="loading"
            class="saveBtn"
            @click="mutiDelChild"
            style="margin-left: 20px"
            >删除选中子账号</Button
          >
        </Col>
      </Row>
    </div>
    <!-- <div class="query-warp">
            <Button :loading="loading" @click="addChildUser">新增子账号</Button>
        </div> -->
    <div>
      <Table
        :loading="loading"
        size="small"
        :height="556"
        stripe
        :columns="columns"
        :data="list"
        @on-selection-change="selectChange"
      >
        <template #lastLoginDateTime="{ row }">
          <span>{{
            row.lastLoginLog ? row.lastLoginLog.loginDateTime : "尚未登陆"
          }}</span>
        </template>
        <template #charNum="{ row }">
          {{
            row.memberAccount.usableCharNum
              ? row.memberAccount.usableCharNum
              : 0
          }}
          <!-- <a @click="distribution(row.userId)">
            {{
              row.memberAccount.usableCharNum
                ? row.memberAccount.usableCharNum
                : 0
            }}
          </a> -->
        </template>
        <template #voiceNum="{ row }">
          {{
            row.memberAccount.usableVoiceNum
              ? row.memberAccount.usableVoiceNum
              : 0
          }}
          <!-- <a @click="distributionVoice(row.userId)">
            {{
              row.memberAccount.usableVoiceNum
                ? row.memberAccount.usableVoiceNum
                : 0
            }}
          </a> -->
        </template>
        <template #imageNum="{ row }">
          {{
            row.memberAccount.usableImageNum
              ? row.memberAccount.usableImageNum
              : 0
          }}
          <!-- <a @click="distributionVoice(row.userId)">
            {{
              row.memberAccount.usableVoiceNum
                ? row.memberAccount.usableVoiceNum
                : 0
            }}
          </a> -->
        </template>
        <template #enabled="{ row }">
          <a
            v-if="row.enabled == 1"
            style="color: #19be6b"
            @click="changeEnabled(row)"
            >启用</a
          >
          <a v-else style="color: #ed4014" @click="changeEnabled(row)">禁用</a>
        </template>
        <template #operate="{ row }">
          <a
            style="margin-right: 20px; color: #343434"
            @click="distribution(row.userId)"
            >分配</a
          >
          <!-- <a
            style="margin-right: 20px; color: #ffa41b"
            @click="updateChildUser(row.userId)"
            >修改</a
          > -->
          <a
            style="color: #666666; margin-right: 20px"
            @click="resetPassword(row.userId)"
            >修改密码</a
          >
          <a style="color: #ed4014" @click="delChild(row)">删除</a>
        </template>
        <template #option="{ row }">
          <a style="padding-right: 5px" @click="distribution(row.userId)"
            >分配字符</a
          >
          <a style="padding-right: 5px" @click="distributionVoice(row.userId)"
            >分配语音</a
          >
          <!-- <a style="padding-right: 5px" @click="updateChildUser(row.userId)"
            >修改</a
          > -->
          <a @click="resetPassword(row.userId)">修改密码</a>
        </template>
      </Table>
    </div>
    <div style="text-align: right">
      <Page
        style="margin-top: 20px"
        show-total
        :total="page.total"
        :page-size="page.size"
        v-model="page.num"
        @on-change="pageChange"
      />
    </div>
    <ChildUserDrawer ref="ChildUserDrawerRef" @on-success="childUserSaveSucc" />
    <ChildUserModal ref="ChildUserModalRef" @on-success="childUserSaveSucc" />
    <ChildUserPwdModal
      ref="ChildUserPwdModalRef"
      @on-success="childUserSaveSucc"
    />
    <ChildUserDistributionModal
      ref="ChildUserDistributionModalRef"
      @on-success="childUserSaveSucc"
    />

    <ChildUserDistributionDrawer
      ref="ChildUserDistributionDrawerRef"
      @on-success="childUserSaveSucc"
    />
    <ChildUserVoiceDistributionDrawer
      ref="ChildUserVoiceDistributionDrawerRef"
      @on-success="childUserSaveSucc"
    />
  </div>
</template>

<script>
import PageHeader from "../components/common/pageHeader.vue";
import ChildUserModal from "@/components/ChildUserModal";
import ChildUserPwdModal from "@/components/ChildUserPwdModal";
import ChildUserDrawer from "@/components/ChildUserDrawer";
import ChildUserDistributionDrawer from "@/components/ChildUserDistributionDrawer";
import ChildUserVoiceDistributionDrawer from "@/components/ChildUserVoiceDistributionDrawer";
import ChildUserDistributionModal from "@/components/ChildUserDistributionModal";
import userService from "@/service/user/index.js";
export default {
  components: {
    ChildUserDrawer,
    ChildUserDistributionDrawer,
    ChildUserVoiceDistributionDrawer,
    PageHeader,
    ChildUserModal,
    ChildUserDistributionModal,
    ChildUserPwdModal,
  },
  name: "UserCenterChild",
  data() {
    return {
      loading: false,
      curSel: null,
      columns: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        { type: "index", title: "序号", width: 90 },
        { title: "账号", key: "account" },
        { title: "昵称", key: "nickname" },
        { title: "最后登陆时间", slot: "lastLoginDateTime", width: 200 },
        { title: "字符数量", slot: "charNum" },
        { title: "语音条数", slot: "voiceNum" },
        { title: "图片张数", slot: "imageNum" },
        { title: "是否启用", slot: "enabled" },
        { title: "操作", width: 220, slot: "operate" },
        // { title: "操作", width: 150, slot: "option" },
      ],
      page: {
        size: 10,
        num: 1,
        total: 0,
      },
      list: [],
      account: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loadData();
    },
    selectChange(sel) {
      this.curSel = sel;
      console.log("已选中项：", sel)
    },
    async loadData() {
      let params = {
        pageSize: this.page.size,
        pageNum: this.page.num,
        account: this.account ? this.account.trim() : "",
      };
      this.loading = true;
      let result = await userService.page(params);
      this.loading = false;
      this.page.total = result.total;
      this.list = result.rows;
    },
    pageChange() {
      this.loadData();
    },
    addChildUser() {
      this.$refs.ChildUserModalRef.open();
    },
    distribution(userId) {
      this.$refs.ChildUserDistributionModalRef.open(userId);
      // this.$refs.ChildUserDistributionDrawerRef.open(userId);
    },
    distributionVoice(userId) {
      this.$refs.ChildUserVoiceDistributionDrawerRef.open(userId);
    },
    updateChildUser(userId) {
      this.$refs.ChildUserModalRef.open(userId);
    },
    changeEnabled(row) {
      let { userId, account, enabled } = row;
      let status =
        enabled == 1
          ? '<span style="color:red">禁用</span>'
          : '<span style="color:green">启用</span>';
      let content = "您确定要 [" + status + "] 用户 [" + account + "] 吗?";
      this.$Modal.confirm({
        title: "温馨提示",
        content,
        loading: true,
        onOk: async () => {
          let e = enabled == 1 ? 0 : 1;
          let result = await userService.changeEnabled({ userId, enabled: e });
          this.$Modal.remove();
          if (result) {
            this.$Message.success("成功修改[" + result + "]个账号");
            this.loadData();
          }
        },
      });
    },
    delChild(row) {
      let { userId, account } = row;
      let content = "您确定要 [删除] 用户 [" + account + "] 吗?";
      this.$Modal.confirm({
        title: "温馨提示",
        content,
        loading: true,
        onOk: async () => {
          let result = await userService.delChild({ userId });
          this.$Modal.remove();
          if (result) {
            this.$Message.success("删除成功!");
            this.loadData();
          }
        },
      });
    },
    mutiDelChild() {
      if(!this.curSel || this.curSel.length === 0) {
        this.$Message.info("当前暂无勾选项！");
        return
      }
      let accList = this.curSel.map((c)=>{
        if(c.account) {
          return c.account
        }
      })
      let ids = this.curSel.map((c)=>{
        if(c.userId) {
          return c.userId
        }
      })
      let content = "您确定要 [删除] 用户 [" + accList.join(",") + "] 吗?";
      this.$Modal.confirm({
        title: "温馨提示",
        content,
        loading: true,
        onOk: async () => {
          let result = await userService.delChild({ childrenIds: ids });
          this.$Modal.remove();
          if (result) {
            this.$Message.success("删除成功!");
            this.loadData();
          }
        },
      });
    },
    resetPassword(userId) {
      this.$refs.ChildUserPwdModalRef.open(userId);
      // this.$Modal.confirm({
      //   title: "提示",
      //   content: `<p>你确定将 <strong> ${user.account} </strong>的密码重置为<strong> 123456 </strong>吗</p>`,
      //   onOk: async () => {
      //     await userService.resetPassword(user.userId);
      //     this.$Message.success("重置成功");
      //   },
      // });
    },
    childUserSaveSucc() {
      this.loadData();
    },
  },
};
</script>

