<template>
  <div class="sideBarBox">
    <div class="sideBar">
      <div class="tips">扫码咨询售前客服</div>
      <div class="splitLine"></div>
      <div v-for="(item, idx) in QRCodes" :key="idx">
        <el-tooltip
          :content="getQRCodeImg(item)"
          raw-content
          placement="left"
          effect="light"
        >
          <div class="itemBox cursor-pointer" @click="openWeb(idx)">
            <img :src="item.logo" alt="" />
            <div>{{ item.desc }}</div>
          </div>
        </el-tooltip>
      </div>
      <div class="freeTest cursor-pointer" @click="freeTest">
        免费<br />测试
      </div>
    </div>
    <div class="sideBar1">
      <div class="tips">扫码咨询售后客服</div>
      <div class="splitLine"></div>
      <template v-for="(item, idx) in afterSaleQRCodes" :key="idx">
        <div v-if="item.imgSrcArr.length > 0">
          <el-tooltip
            :content="getAfterSaleImg(item)"
            raw-content
            placement="left"
            effect="light"
          >
            <div class="itemBox cursor-pointer" @click="openWeb1(idx)">
              <img :src="item.logo" alt="" />
              <div>{{ item.desc }}</div>
            </div>
          </el-tooltip>
        </div>
      </template>
      <div class="freeTest cursor-pointer" @click="showModal = true">
        客服<br />验证
      </div>
    </div>
    <!-- <div
      class="customer cursor-pointer"
      @click="showModal = true"
      style="font-size: 14px; line-height: 18px"
    >
      <div style="margin-top: 0">客服验证</div>
    </div> -->
    <Modal v-model="showError" @on-ok="showError = false" title="验证结果">
      <div style="color: rgb(192, 12, 12)">
        注意：该账号未通过验证，请谨慎操作！
      </div>
      <template #footer>
        <div style="text-align: center">
          <Button class="saveBtn" long @click="showError = false">确认</Button>
        </div>
      </template>
    </Modal>
    <Modal v-model="showSuccess" @on-ok="showError = false" title="验证结果">
      <div style="color: rgb(13, 128, 0)">客服账号验证通过！</div>
      <template #footer>
        <div style="text-align: center">
          <Button class="saveBtn" long @click="showSuccess = false"
            >确认</Button
          >
        </div>
      </template>
    </Modal>
    <Modal
      v-model="showModal"
      :loading="loading"
      @on-ok="submit"
      title="验证客服"
    >
      <div>
        <Form
          style="padding: 0px"
          ref="form"
          :model="formData"
          :rules="rules"
          :label-width="120"
        >
          <FormItem label="客服平台" prop="platform">
            <Select v-model="formData.platform" placeholder="请选择客服平台">
              <Option v-for="v in platformList" :value="v" :key="v">
                {{ v }}
              </Option>
            </Select>
          </FormItem>
          <FormItem
            :label="
              formData.platform === 'Telegram'
                ? '客服ID'
                : formData.platform === 'WeChat'
                ? '客服用户名'
                : '客服号码'
            "
            prop="account"
          >
            <Input
              v-model="formData.account"
              :placeholder="
                formData.platform === 'Telegram'
                  ? '请输入客服ID'
                  : formData.platform === 'WeChat'
                  ? '请输入客服用户名'
                  : '请输入客服号码'
              "
            ></Input>
          </FormItem>
        </Form>
      </div>
      <template #footer>
        <div style="text-align: center">
          <Button class="saveBtn" long :loading="loading" @click="submit"
            >验证</Button
          >
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import Wechat from "@/assets/imgs/footer/wechat.png";
import whatsapp from "@/assets/imgs/footer/whatsapp.png";
import telegram from "@/assets/imgs/footer/telegram.png";
import telegramLogo from "@/assets/imgs/sidebar/telegram.png";
import whatsappLogo from "@/assets/imgs/sidebar/whatsapp.png";
import wechatLogo from "@/assets/imgs/sidebar/wx.png";
// import customer from "@/assets/imgs/sidebar/Frame.svg";
import service from "@/service/customerService";
export default {
  data() {
    return {
      showModal: false,
      showError: false,
      showSuccess: false,
      formData: {
        platform: "",
        account: "",
      },
      platformList: [],
      rules: {
        platform: [
          { required: true, message: "请选择客服平台", trigger: "blur" },
        ],
        account: [
          { required: true, message: "请输入客服账号", trigger: "blur" },
        ],
      },
      Wechat,
      whatsapp,
      telegram,
      telegramLogo,
      whatsappLogo,
      wechatLogo,
      QRCodes: [
        {
          desc: "微信",
          name: "wxchat",
          imgSrc: Wechat,
          logo: wechatLogo,
          imgSrcArr: [],
          urlArr: [],
        },
        {
          desc: "Whatsapp",
          imgSrc: whatsapp,
          name: "whatsapp",
          logo: whatsappLogo,
          imgSrcArr: [],
          urlArr: [],
        },
        {
          desc: "Telegram",
          imgSrc: telegram,
          name: "telegram",
          logo: telegramLogo,
          imgSrcArr: [],
          urlArr: [],
        },
      ],
      afterSaleQRCodes: [
        {
          desc: "微信",
          name: "wxchat",
          logo: wechatLogo,
          imgSrcArr: [],
          urlArr: [],
        },
        {
          desc: "Whatsapp",
          name: "whatsapp",
          logo: whatsappLogo,
          imgSrcArr: [],
          urlArr: [],
        },
        {
          desc: "Telegram",
          name: "telegram",
          logo: telegramLogo,
          imgSrcArr: [],
          urlArr: [],
        },
      ],
    };
  },
  async mounted() {
    let res = await service.list({ height: 800, width: 800 });
    if (res && res.length > 0) {
      res.forEach((j) => {
        let platform = j.platform.toLowerCase();
        if (j.accountList?.length > 0) {
          j.accountList.forEach((r) => {
            if (r.accountType != 1) {
              //售前
              if (platform === "wechat") {
                this.QRCodes[0].imgSrcArr.push(r.qrCode);
                this.QRCodes[0].urlArr.push(r.referUrl);
              } else if (platform === "whatsapp") {
                this.QRCodes[1].imgSrcArr.push(r.qrCode);
                this.QRCodes[1].urlArr.push(r.referUrl);
              } else if (platform === "telegram") {
                this.QRCodes[2].imgSrcArr.push(r.qrCode);
                this.QRCodes[2].urlArr.push(r.referUrl);
              }
            } else {
              //售后
              if (platform === "wechat") {
                this.afterSaleQRCodes[0].imgSrcArr.push(r.qrCode);
                this.afterSaleQRCodes[0].urlArr.push(r.referUrl);
              } else if (platform === "whatsapp") {
                this.afterSaleQRCodes[1].imgSrcArr.push(r.qrCode);
                this.afterSaleQRCodes[1].urlArr.push(r.referUrl);
              } else if (platform === "telegram") {
                this.afterSaleQRCodes[2].imgSrcArr.push(r.qrCode);
                this.afterSaleQRCodes[2].urlArr.push(r.referUrl);
              }
            }
          });
        }
      });
      this.QRCodes = JSON.parse(JSON.stringify(this.QRCodes));
    }
    this.getPlatformList();
  },
  methods: {
    async getPlatformList() {
      let res = await service.platformList();
      if (res && res.length > 0) {
        this.platformList = res;
      }
    },
    getQRCodeImg(item) {
      if (item.imgSrcArr.length > 0) {
        let str = "";
        item.imgSrcArr.forEach((i, idx) => {
          str += `<a href="${item.urlArr[idx]}" target="_blank"><img src=${i} style="height: 160px; width: 160px;display:inline" /></a>`;
        });
        return str;
      } else {
        return (
          "<img src=" +
          item.imgSrc +
          " style='height: 160px; width: 160px;display:inline'/>"
        );
      }
    },
    getAfterSaleImg(item) {
      let str = "";
      item.imgSrcArr.forEach((i, idx) => {
        str += `<a href="${item.urlArr[idx]}" target="_blank"><img src=${i} style="height: 160px; width: 160px;display:inline" /></a>`;
      });
      return str;
    },
    submit() {
      let { account, platform } = this.formData;
      let params = { account, platform };
      let sub = async () => {
        this.loading = true;
        let result = await service.valid(params);
        this.loading = false;
        this.showModal = false;
        if (!result) {
          this.showError = true;
        } else {
          this.showSuccess = true;
        }
        // this.$Message.success("客服账号验证通过！");
      };
      this.$refs.form.validate((valid) => {
        if (valid) {
          sub();
        } else {
          return;
        }
      });
    },
    freeTest() {
      this.$emit("on-free-test");
    },
    openWeb(idx) {
      if (this.QRCodes?.[idx]?.urlArr?.[0]) {
        window.open(this.QRCodes[idx].urlArr[0], "_blank");
      }
    },
    openWeb1(idx) {
      if (this.afterSaleQRCodes?.[idx]?.urlArr?.[0]) {
        window.open(this.afterSaleQRCodes[idx].urlArr[0], "_blank");
      }
      if (idx == 1) {
        window.open("https://wa.me/8619938785868", "_blank");
      }
    },
  },
};
</script>
<style scoped>
.sideBarBox {
  top: calc(40% - 170px);
  right: 10px;
  position: fixed;
}
.sideBar,
.sideBar1 {
  width: 54px;
  height: 350px;
  flex-shrink: 0;
  border-radius: 26px;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 4px 4px 0px rgba(156, 156, 156, 0.25);
  backdrop-filter: blur(10px);
  padding: 12px 12px 4px;
}
.sideBar1 {
  height: auto !important;
}
.sideBar > div:not(:first-child) {
  margin-top: 16px;
}

.sideBar1 {
  margin-top: 20px;
}
.sideBar1 > div:not(:first-child) {
  margin-top: 16px;
}
.tips {
  color: #323232;
  text-align: center;
  font-family: Inter;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0.87rem -12px 0;
}
.splitLine {
  width: 1.625rem;
  height: 0.0625rem;
  background: #c9c9c9;
  margin: 0.7rem auto;
}
.itemBox div {
  color: #343434;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  margin-top: 5px;
  word-wrap: break-word;
  text-align: center;
}
.freeTest {
  margin-top: 18px !important;
  border-radius: 46px;
  width: 46px;
  height: 46px;
  color: #fff;
  background: linear-gradient(180deg, #5192fd 0%, #1d6ced 100%);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-left: -8px;
  text-align: center;
  padding-top: 8px;
}
.customer {
  width: 54px;
  height: 54px;
  margin-top: 30px;
  text-align: center;
  border-radius: 43px;
  background: linear-gradient(180deg, #5192fd 0%, #1d6ced 100%);
  box-shadow: 0px 4px 6.7px 0px rgba(157, 157, 157, 0.25);
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  padding: 9px;
}
.customer img {
  display: inline;
}
.customer div {
  margin-top: 2px;
}
@media (max-width: 768px) {
}
</style>