<template>
  <div
    class="bannerBox"
    :style="{
      backgroundImage: 'url(' + bgSrc + ')',
      backgroundPosition: isMobile ? 'center -40%' : 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      padding: isMobile ? '2.44rem 0' : padding,
      position: 'relative',
    }"
  >
    <div class="carousel" v-if="showCarousel">
      <el-carousel style="height: 100%">
        <el-carousel-item v-for="(item, idx) in carouselList" :key="idx">
          <div style="height: 100%; width: 100%; position: relative">
            <img :src="item.url" alt="" style="height: 100%; width: 100%" />
            <img :src="item.pUrl" alt="" class="PCImg" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="mainContent">
      <div class="title1">
        {{ !isMobile ? title1 : "易翻译-电商客服辅助翻译平台" }}
      </div>
      <div class="title2" v-if="!isMobile">{{ title2 }}</div>
      <div class="desc">{{ desc }}</div>
      <div
        class="downloadBtn cursor-pointer inline-block"
        v-if="showDownload && !isMobile"
        @click="toDownload"
      >
        立即下载
      </div>
      <div
        class="downloadBtn cursor-pointer inline-block"
        v-if="showValid && !isMobile"
        @click="toDownload"
      >
        客服验证
      </div>
    </div>
  </div>
</template>
<script>
// import bannerBg from "@/assets/imgs/home/bannerBg.png";
// import bannerBg0 from "@/assets/imgs/home/bannerBg0.png";
// import bannerBg1 from "@/assets/imgs/price/priceBg.png";
// import mBanner1 from "@/assets/imgs/mobile/banner/mBanner1.png";
// import mBanner2 from "@/assets/imgs/mobile/banner/mBanner2.png";
// import mBanner3 from "@/assets/imgs/mobile/banner/mBanner3.png";
import mBanner0 from "@/assets/imgs/mobile/banner/mBanner0.png";
import PC0 from "@/assets/imgs/home/PC0.png";
import PC1 from "@/assets/imgs/home/PC1.png";
import PC2 from "@/assets/imgs/home/PC2.png";
import PC3 from "@/assets/imgs/home/PC3.png";
export default {
  props: {
    showCarousel: {
      type: Boolean,
      default: false,
    },
    bgSrc: String,
    title1: {
      type: String,
      default: "易翻译",
    },
    title2: {
      type: String,
      default: "电商客服辅助翻译平台",
    },
    desc: {
      type: String,
      default: "专业、快捷、高效的翻译软件",
    },
    showDownload: {
      type: Boolean,
      default: true,
    },
    showValid: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: String,
      default: "7.9375rem 0 12.9375rem",
    },
  },
  data() {
    const isMobile =
      window.screen.width > 768 || window.screen.width == 768 ? false : true;
    return {
      carouselList: [
        {
          url: isMobile ? mBanner0 : PC0,
          pUrl: PC1,
        },
        {
          url: isMobile ? mBanner0 : PC0,
          pUrl: PC2,
        },
        {
          url: isMobile ? mBanner0 : PC0,
          pUrl: PC3,
        },
      ],
      isMobile,
    };
  },
  methods: {
    toDownload() {
      this.$emit("toDownload");
    },
  },
};
</script>
<style scoped>
.carousel {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
}
.PCImg {
  position: absolute;
  right: 20%;
  top: 22%;
  height: 350px;
  width: auto;
}
.mainContent {
  position: relative;
  z-index: 999;
}
.title1,
.title2 {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3rem;
  text-align: left;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.title2 {
  margin-top: 0.5rem;
}
.desc {
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-align: left;
  color: #042967;
  margin: 1.625rem 0 2.25rem;
}
.downloadBtn {
  line-height: 1.1875rem;
  text-align: center;
  background: linear-gradient(180deg, #5192fd 0%, #1d6ced 100%);
  padding: 0.875rem 1.75rem;
  border-radius: 0.5rem;
  color: var(--vt-c-white);
}
@media (max-width: 768px) {
  .bannerBox {
    height: 22.5rem;
  }
  .PCImg {
    position: absolute;
    right: calc(50% - 100px);
    top: 35%;
    width: 200px;
    height: auto;
  }
  .title1 {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .desc {
    color: #042967;
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0.65rem;
    text-align: center;
  }
}
</style>
<style>
.bannerBox .el-carousel__container {
  height: 100%;
}
.bannerBox .el-carousel__indicators--horizontal {
  bottom: 70px;
}
.bannerBox .el-carousel__indicator--horizontal button {
  height: 8px;
  width: 8px;
  border-radius: 16px;
  opacity: 1;
}

.bannerBox .el-carousel__indicator--horizontal.is-active button {
  background: #2470ef;
  width: 26px;
}
</style>