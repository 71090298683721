<template>
  <!-- 产品说明 -->
  <div class="productDesc">
    <div class="mainContent">
      <div class="leftTree" v-if="!isMobile">
        <el-tree
          :data="treeData"
          :props="defaultProps"
          node-key="id"
          :expand-on-click-node="true"
          @node-click="handleNodeClick"
          :render-content="renderContent"
          class="custom-tree"
          :accordion="true"
        ></el-tree>
      </div>
      <div class="mainBody">
        <Info :curNodeContent="curNodeContent" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, toRaw } from "vue";
import { ElTree, ElIcon } from "element-plus";
import Info from "./productDescInfo.vue";
import {
  Star,
  Document,
  Setting,
  Search,
  User,
  Present,
  Promotion,
  Platform,
  Wallet,
  Download,
  Edit,
  Management,
  DocumentAdd,
  Menu,
  Iphone,
  Notebook,
  Message,
  MessageBox,
  ElementPlus,
  UserFilled,
  Refresh,
} from "@element-plus/icons-vue";
const isMobile =
  window.screen.width > 768 || window.screen.width == 768 ? false : true;
const curNodeContent = ref({
  label: "易翻译产品教程",
  icon: Star,
  id: "cpjc",
});
const treeData = ref([
  {
    label: "易翻译产品教程",
    icon: Star,
    id: "cpjc",
  },
  {
    label: "入门指南",
    icon: Promotion,
    children: [
      { label: "新手必看", icon: Document, id: "xsbk" },
      { label: "注册&登录", icon: Document, id: "zcdl" },
      { label: "下载&安装", icon: Download, id: "xzaz" },
    ],
  },
  {
    label: "功能详解",
    icon: Search,
    children: [
      {
        label: "易翻译官网控制台",
        icon: Platform,
        children: [
          { label: "会员信息", icon: User, id: "hyxx" },
          { label: "会员充值", icon: Wallet, id: "hycz" },
          { label: "子账号管理", icon: Management, id: "zzhgl" },
          { label: "其他功能", icon: Edit, id: "xgmm" },
          { label: "推广有礼", icon: Present, id: "tgyl" },
        ],
      },
      {
        label: "易翻译客户端",
        icon: Platform,
        children: [
          { label: "用户中心", icon: User, id: "yhzx" },
          { label: "添加应用", icon: DocumentAdd, id: "tjyy" },
          { label: "应用管理", icon: Menu, id: "yygl" },
          { label: "翻译设置", icon: Setting, id: "gysz" },
          { label: "快捷回复", icon: Iphone, id: "kjhf" },
          { label: "粉丝备注", icon: Notebook, id: "fsbz" },
          { label: "群发消息", icon: Message, id: "qfxx" },
          { label: "AI回复", icon: MessageBox, id: "aihf" },
          { label: "代理设置", icon: Setting, id: "dlsz" },
          { label: "系统设置", icon: Setting, id: "xtsz" },
        ],
      },
    ],
  },
  {
    label: "特色功能",
    icon: Star,
    children: [
      { label: "智能AI", icon: ElementPlus, id: "znai" },
      { label: "图片翻译", icon: Refresh, id: "tpfy" },
      { label: "客户中心", icon: UserFilled, id: "khzx" },
      { label: "显示设置", icon: Setting, id: "xssz" },
    ],
  },
]);
const defaultProps = {
  children: "children",
  label: "label",
};

const renderContent = (h, { node, data }) => {
  const level = node.level; // 获取节点层级
  const isPrimaryNode = level === 1; // 判断是否为一级节点

  return h(
    "div",
    {
      class: [
        "node-content",
        { "primary-node": isPrimaryNode, "child-node": !isPrimaryNode },
      ],
    },
    [
      // 左侧的图标
      h(
        ElIcon,
        { style: { marginRight: "8px", height: "1.1rem", width: "1.1rem" } },
        () => h(data.icon)
      ),
      // 节点标签
      h("span", data.label),
    ]
  );
};

const handleNodeClick = (data) => {
  console.log("Clicked node:", data, toRaw(data));
  let nodeData = toRaw(data);
  if (nodeData.id) {
    curNodeContent.value = toRaw(nodeData);
  }
};

onMounted(() => {});
</script>
<style scoped>
.productDesc {
  background: url("../../assets/imgs/home/demand/demandBg.png");
}
.mainContent {
  padding: 3rem 0 8.125rem;
  display: flex;
  width: 1300px !important;
}
.leftTree {
  width: 32%;
}
.mainBody {
  width: 68%;
  padding-left: 2rem;
}
.leftIcon {
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 0.4rem;
}
.alignCenter {
  display: flex;
  align-items: center;
}
.bodyTitle {
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-weight: 600;
}
.rightIcon {
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 0.6rem;
}
.bodyDesc {
  margin: 0.6rem 0;
  color: #676767;
}

@media (max-width: 768px) {
  .mainBody {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .mainContent {
    width: auto !important;
  }
}
</style>
<style>
.el-tree {
  background: transparent !important;
}
.custom-tree .el-tree-node {
  position: relative;
}
.custom-tree .el-tree-node__expand-icon {
  position: absolute;
  right: 2%;
  scale: 1.1;
}
.custom-tree .el-tree-node__label {
  padding-left: 15px;
}

/* 全局树样式 */
.custom-tree .el-tree-node__content {
  display: flex;
  align-items: center;
  padding: 0px 12px; /* 设置节点的上下间距 */
  line-height: 1.8; /* 设置行高以增加节点的上下间距 */
  height: auto !important;
}

/* 一级节点样式 */
.node-content.primary-node {
  font-weight: bold;
  font-size: 16px; /* 更大字体 */
  color: #000;
  padding: 10px 0; /* 一级节点的上下间距 */
  padding-left: 10px;
  display: flex;
  align-items: center;
}
.node-content.primary-node span {
  font-weight: bold;
}

/* 子节点样式，包含左侧层级线条和上下间距 */
.node-content.child-node {
  font-size: 14px;
  color: #666;
  padding-left: 16px; /* 子节点缩进 */
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
}

/* 悬停效果 */
.custom-tree .el-tree-node__content:hover {
  background-color: #f5f7fa;
}

.custom-tree .el-tree-node.is-expanded::before {
  content: "";
  position: absolute;
  left: -8px; /* 控制竖线的位置 */
  top: 0;
  bottom: 0;
  width: 2px;
  z-index: 9;
  /* background-color: #dcdcdc; */
}

/* 当前选中 */
.custom-tree .is-current > .el-tree-node__content > .child-node {
  color: #3581ff !important; /* 设置选中节点的字体颜色 */
}
.custom-tree .is-current .el-tree-node__content {
  position: relative;
}
.custom-tree .is-current > .el-tree-node__content::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  z-index: 999;
  border-radius: 2px;
  background-color: #3581ff;
}
.custom-tree > .is-current > .el-tree-node__content::before {
  background-color: transparent !important;
}
</style>