<template>
  <div class="demand">
    <div>
      <div class="moduleTitle">
        {{ mainTitle ? mainTitle : "根据需求，选择适合您的套餐" }}
      </div>
      <div class="saleBox">
        <div v-for="(sale, idx) in sales" :key="sale.label">
          <div class="saleGrpTitle">{{ sale.label }}</div>
          <div class="sale-warp">
            <div
              class="sale-item"
              :class="{ act: selected.id == item.id }"
              v-for="item in sale.list"
              :key="item.id"
              @click="selectedSale(item)"
            >
              <template v-if="idx === 0">
                <div
                  class="imgTitle1"
                  :style="'background-image: url(' + getSelBGImg(item) + ')'"
                >
                  <div class="name">
                    {{ item.name }}
                  </div>
                  <div class="desc">
                    {{ getDesc(item.name) }}
                  </div>
                </div>
                <div class="info">
                  <el-row class="mb16">
                    <el-col :span="12">字符数</el-col>
                    <el-col :span="12"
                      ><div
                        v-if="item.translationBillingMode == 'char'"
                        style="white-space: nowrap"
                      >
                        <span v-if="item.num <= 9999"
                          >{{ item.num }} 个字符</span
                        >
                        <span v-else>{{ item.num / 10000 }} 万字符</span>
                      </div>
                      <div v-else style="white-space: nowrap">
                        {{ (item.num / 60 / 24 / 30).toFixed(0) }} 个月
                      </div></el-col
                    >
                  </el-row>
                  <el-row>
                    <el-col :span="12">支付金额</el-col>
                    <el-col :span="12" class="price"
                      >{{ item.salePrice / 100 }} 元</el-col
                    >
                  </el-row>
                </div>
                <div class="tz">
                  <el-row
                    :style="{
                      borderBottom:
                        selected.id == item.id ? '1px solid #e6e6e6' : 'none',
                    }"
                  >
                    <el-col :span="12" class="tzCol"
                      ><img :src="support" alt="" />无时间限制</el-col
                    >
                    <el-col :span="12" class="tzCol"
                      ><img :src="support" alt="" />支持子账号</el-col
                    >
                  </el-row>
                </div>
                <div class="testBtnBox" v-if="selected.id == item.id">
                  <div class="testBtn" @click="freeTest">免费测试</div>
                </div>
              </template>
              <template v-else>
                <div
                  class="imgTitle2"
                  style="
                    background: linear-gradient(to right, #1051ff, #3dafff);
                  "
                >
                  <div class="name">{{ item.name }}（1个月）</div>
                </div>
                <div class="info">
                  <el-row class="mb16">
                    <el-col :span="12">字符数</el-col>
                    <el-col :span="12">不限量</el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">支付金额</el-col>
                    <el-col :span="12" class="price"
                      >{{ item.salePrice / 100 }} 元</el-col
                    >
                  </el-row>
                </div>
                <div class="tz">
                  <el-row
                    :style="{
                      borderBottom:
                        selected.id == item.id ? '1px solid #e6e6e6' : 'none',
                    }"
                  >
                    <el-col :span="12" class="tzCol"
                      ><img
                        :src="warnSupport"
                        alt=""
                        style="height: 22px; width: 22px; margin-top: -4px"
                      />时间限制30天</el-col
                    >
                    <el-col :span="12" class="tzCol"
                      ><img :src="notSupport" alt="" />不支持子账号</el-col
                    >
                  </el-row>
                </div>
                <div class="testBtnBox" v-if="selected.id == item.id">
                  <div class="testBtn" @click="freeTest">免费测试</div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import memberGroupService from "@/service/member/group";
import support from "@/assets/imgs/home/demand/support.png";
import notSupport from "@/assets/imgs/home/demand/notSupport.png";
import warnSupport from "@/assets/imgs/home/demand/warnSupport.png";

import ai1 from "@/assets/userCenter/ai1.png";
import ai2 from "@/assets/userCenter/ai2.png";
import bz1 from "@/assets/userCenter/bz1.png";
import bz2 from "@/assets/userCenter/bz2.png";
import gj1 from "@/assets/userCenter/gj1.png";
import gj2 from "@/assets/userCenter/gj2.png";
import zy1 from "@/assets/userCenter/zy1.png";
import zy2 from "@/assets/userCenter/zy2.png";
export default {
  props: {
    mainTitle: String,
  },
  data() {
    return {
      isMobile:
        window.screen.width > 768 || window.screen.width == 768 ? false : true,
      support,
      notSupport,
      warnSupport,
      ai1,
      ai2,
      bz1,
      bz2,
      gj1,
      gj2,
      zy1,
      zy2,
      selected: {},
      sales: [],
    };
  },
  created() {
    this.loadSales();
  },
  methods: {
    freeTest() {
      this.$emit("freeTest");
    },
    async loadSales() {
      let sales = await memberGroupService.saleList();
      let time = { label: "包月用户", list: [] };
      let char = { label: "字符用户", list: [] };
      for (let i = 0; i < sales.length; i++) {
        let item = sales[i];
        let target = item.translationBillingMode == "char" ? char : time;
        target.list.push(item);
      }
      this.sales.push(char);
      this.sales.push(time);
    },
    selectedSale(item) {
      this.selected = item;
    },
    getBGImg(item) {
      switch (item.name) {
        case "高级版":
          return gj1;
        case "专业版":
          return zy1;
        case "标准版":
          return bz1;
        case "AI翻译":
          return ai1;
        default:
          return bz1;
      }
    },
    getSelBGImg(item) {
      switch (item.name) {
        case "高级版":
          return gj2;
        case "专业版":
          return zy2;
        case "标准版":
          return bz2;
        case "AI翻译":
          return ai2;
        default:
          return bz2;
      }
    },
    getDesc(name) {
      switch (name) {
        case "高级版":
          return "支持谷歌、微软两种翻译通道任意切换";
        case "专业版":
          return "支持Deepl精准翻译";
        case "标准版":
          return "支持腾讯、有道两种翻译通道任意切换";
        case "AI翻译":
          return "支持ChatGpt智能翻译";
        case "GPT会员":
          return "支持ChatGpt智能翻译";
        default:
          return "支持腾讯、有道两种翻译通道任意切换";
      }
    },
  },
};
</script>
<style scoped lang="scss">
.demand {
  background: url("../../assets/imgs/home/demand/demandBg.png");
  background-size: cover;
  background-repeat: repeat;
  padding: 5.625rem 0;
  font-size: 0.875rem;
}
.mb16 {
  margin-bottom: 12px;
}
.tz {
  padding: 0 22px;
  margin-top: 12px;
  > div {
    padding-bottom: 12px;
  }
  .tzCol {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
}
.saleBox {
  width: 1300px;
  margin: 0 auto;
  > div:first-child {
    margin-bottom: 60px;
  }
}
.moduleTitle {
  margin-bottom: 5rem;
}
.titleArea {
  padding: 1.625rem 0 0.875rem;
  text-align: center;
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
}
.title {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}
.titleDesc {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  margin: 0.5rem 0;
}
.mainArea {
  padding: 0 1.875rem 0 2.5rem;
  background-color: #fff;
  min-height: 27.5rem;
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.mainArea > div {
  padding: 1.5rem 0;
}
.mainArea > div:first-child {
  border-bottom: 1px solid #e6e6e6;
}
.tcName {
  display: inline-block;
  border-radius: 1.375rem;
  padding: 0.5rem 0.9375rem;
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  background: #f6f6f6;
}
.countArea {
  margin: 1.625rem 0 1.75rem;
  color: #000;
}
.unit {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
}
.count {
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  margin-left: 0.125rem;
}
.countDesc {
  color: #9a9a9a;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  margin-left: 1.25rem;
}
.mainArea .featureBox {
  margin-bottom: 0.8125rem;
}
.mainArea .featureBox:last-child {
  margin-bottom: 0;
}
.featureBox img {
  width: 1.375rem;
  height: 1.375rem;
  margin-right: 0.625rem;
}
.featureBox div {
  color: #333;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 131.25% */
}
.testBtnBox {
  padding: 24px 0;
  text-align: center;
}
.testBtn {
  margin-top: 0;
  border-radius: 1.75rem;
  padding: 0.75rem 1.75rem;
  background: linear-gradient(180deg, #5192fd 0%, #1d6ced 100%);
  color: #fff;
  font-style: normal;
  line-height: 1rem;
  display: inline-block;
  cursor: pointer;
}

.saleGrpTitle {
  font-size: 20px;
  color: #333333;
  font-weight: bold;
  margin: 30px 0;
}
.sale-warp {
  display: flex;
  align-items: baseline;
  .sale-item {
    width: 300px;
    border-radius: 12px;
    border: 1px solid #e6e6e6;
    margin-right: 20px;
    overflow: hidden;
    cursor: pointer;
    &.act {
      border-color: #3581ff;
      box-shadow: 0 6px 16px 0 #ccc;
    }
    .imgTitle1 {
      padding: 8px 22px;
      background-repeat: no-repeat;
      .name {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 0px;
        color: #fff;
      }
      .desc {
        color: #fff;
        font-size: 12px;
        white-space: nowrap;
      }
    }
    .imgTitle2 {
      padding: 14px 22px;
      .name {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 0px;
        color: #fff;
      }
    }
    .info {
      text-align: left;
      padding: 16px 0;
      margin: 0 22px;
      color: #000;
      border-bottom: 1px solid #e6e6e6;
      .price {
        font-weight: bold;
        font-size: 14px;
        color: #d90101;
      }
    }
  }
}
@media (max-width: 768px) {
  .demand {
    padding: 2.5rem 0.75rem 1rem;
    // width: 1440px;
  }
  .saleBox {
    width: 100%;
    > div {
      max-width: 100%;
      > div {
        max-width: 100%;
      }
    }
  }
  .sale-warp {
    flex-wrap: wrap;
  }
  .sale-item {
    margin-bottom: 20px;
  }
  .moduleTitle {
    margin-bottom: 2rem;
  }
  .titleArea {
    padding: 1.2rem 0 0.9rem;
  }
  .title {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .titleDesc {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0.3rem 0;
  }
}
</style>