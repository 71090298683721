import http from "@/utils/http.js"

export default {
    async get() {
        let api = "/user/get";
        let result = await http.get(api);
        return result.success && result.data;
    },
    async changePassword(params) {
        let api = "/user/changePassword";
        let result = await http.post(api, params);
        return result.success && result.data;
    },
    async update(params) {
        let api = "/user/update";
        let result = await http.post(api, params);
        return result.success && result.data;
    },
    async page(params) {
        let api = "/user/page";
        let result = await http.get(api, params);
        return result.success && result.data;
    },
    async saveChildUser(params) {
        let api = "/user/saveChildUser";
        let result = await http.post(api, params);
        return result.success && result.data;
    },
    async getChildInfo(userId) {
        let api = "/user/getChildInfo";
        let params = { userId };
        let result = await http.get(api, params);
        return result.success && result.data;
    },
    async resetPassword(params) {
        let api = "/user/resetPassword";
        // let params = { userId };
        let result = await http.post(api, params);
        return result.success && result.data;
    },
    async distribution(params) {
        let api = "/user/distribution";
        let result = await http.post(api, params);
        return result.success && result.data;
    },
    async autoDistribution(params) {
        let api = "/user/changeAutoDistribution";
        let result = await http.post(api, params);
        return result.success && result.data;
    },
    async changeEnabled(params) {
        let api = "/user/changeEnabled";
        let result = await http.post(api, params);
        return result.success && result.data;
    },
    async delChild(params) {
        let api = "/user/delChild";
        let result = await http.post(api, params);
        return result.success && result.data;
    },
    async distributionVoice(params) {
        let api = "/user/voice/distribution";
        let result = await http.post(api, params);
        return result.success && result.data;
    },
    async distributionAll(params) {
        let api = "/user/all/distribution";
        let result = await http.post(api, params);
        return result.success
    }
}