<template>
  <!-- 产品说明详情页 -->
  <div>
    <template v-if="props.curNodeContent.id == 'cpjc' || isMobile">
      <div class="bodyTitle alignCenter">
        <component
          :is="props.curNodeContent.icon"
          class="rightIcon"
        ></component>
        易翻译产品教程
      </div>
      <div class="bodyDesc">Traneasy外贸出海专业级翻译软件</div>
      <div class="title1">简介</div>
      <div class="mt1">
        <div class="textIndent">
          易翻译助力于外贸出海客户，和不同国家的人聊天沟通的翻译服务，轻松与国际友人进行深度业务上交流。
        </div>
        <div class="textIndent mt0_5">
          支持全球主流社交聊天实时自动双向翻译、支持全球200+语种互翻、支持应用无限多开、进粉计数器、群发打粉等多种实用性功能。
        </div>
      </div>
      <div>
        <img :src="jc1" alt="" class="imgStyle" />
      </div>
      <div class="title1">核心功能</div>
      <div v-for="(item, k) in features" :key="k" class="mt1 textIndent">
        {{ item }}
      </div>
    </template>
    <template v-if="props.curNodeContent.id == 'xsbk' || isMobile">
      <div class="bodyTitle alignCenter">
        <component
          :is="props.curNodeContent.icon"
          class="rightIcon"
        ></component>
        新手必看
      </div>
      <div class="mt2"></div>
      <div v-for="(item, k) in urls" :key="k" class="mt0_5 textIndent">
        <span>{{ item.desc }}：</span>
        <a :href="item.url">{{ item.url }}</a>
      </div>
      <div style="margin-bottom: 15rem" v-if="!isMobile"></div>
    </template>
    <template v-if="props.curNodeContent.id == 'zcdl' || isMobile">
      <div class="bodyTitle alignCenter">
        <component
          :is="props.curNodeContent.icon"
          class="rightIcon"
        ></component>
        注册&登录
      </div>
      <div class="title1">易翻译注册账号</div>
      <div class="mt1">
        <div class="textIndent">① 在官网点击【注册】，进入会员账号注册页面</div>
        <div>
          <img :src="jc2" alt="" class="imgStyle" />
        </div>
        <div class="textIndent mt1">
          ② 按提示输入相对应的内容并勾选用户协议，在点击注册
        </div>
        <div>
          <img :src="jc3" alt="" class="imgStyle" />
        </div>
        <div class="textIndent mt1">
          ③ 易翻译仅支持邮箱注册，不限制邮箱类别；
        </div>
        <div class="textIndent">
          如邮箱未收到验证码，请检查邮箱里的【垃圾箱】~【回收站】。
        </div>
      </div>
      <div class="title1">易翻译账号官网登录</div>
      <div class="mt1">
        <div class="textIndent">① 在官网点击【登录】跳转登录界面</div>
        <div>
          <img :src="jc4" alt="" class="imgStyle" />
        </div>
        <div class="textIndent mt1">
          ② 按提示输入账号密码点击登录（注册时用户名、邮箱均可登录）
        </div>
        <div>
          <img :src="jc5" alt="" class="imgStyle" />
        </div>
        <div class="textIndent mt1">
          ③
          点击【控制台】进入会员账号管理中心，即可查看账号信息、修改密码、会员充值、创建并管理子账号等操作
        </div>
        <div>
          <img :src="jc6" alt="" class="imgStyle" />
        </div>
      </div>
    </template>
    <template v-if="props.curNodeContent.id == 'xzaz' || isMobile">
      <div class="bodyTitle alignCenter">
        <component
          :is="props.curNodeContent.icon"
          class="rightIcon"
        ></component>
        下载&安装
      </div>
      <div class="mt1">
        <div class="textIndent">
          ① 在官网点击【软件下载】&【立即下载】进入下载页面
        </div>
        <div>
          <img :src="jc7" alt="" class="imgStyle" />
        </div>
        <div class="textIndent mt1">
          ② 选择版本点击下载（软件版本之间的区别请咨询官网在线客服）
        </div>
        <div>
          <img :src="jc8" alt="" class="imgStyle" />
        </div>
        <div class="textIndent mt1">
          ③ 下载完成后，进入下载文件的路径，双击运行【易翻译setup×××.exe】
        </div>
        <div>
          <img :src="jc9" alt="" class="imgStyle" />
        </div>
        <div class="textIndent mt0_5">
          安装并运行易翻译，打开软件为此页面即代表安装完成。
        </div>
        <div>
          <img :src="jc10" alt="" class="imgStyle" />
        </div>
        <div class="textIndent mt0_5" style="color: red">
          温馨提示：安装包是下载后直接点击运行安装即可打开；解压包是需要先解压到某个文件夹里在打开应用程序；如有不清楚或者安装中遇到问题，请联系官网客服咨询解决。
        </div>
      </div>
    </template>
    <template v-if="props.curNodeContent.id == 'hyxx' || isMobile">
      <div class="bodyTitle alignCenter">
        <component
          :is="props.curNodeContent.icon"
          class="rightIcon"
        ></component>
        会员信息
      </div>
      <div class="mt1">
        <div class="textIndent">显示会员账号的套餐信息和有效期。</div>
        <div>
          <img :src="jc11" alt="" class="imgStyle" />
        </div>
      </div>
    </template>
    <template v-if="props.curNodeContent.id == 'hycz' || isMobile">
      <div class="bodyTitle alignCenter">
        <component
          :is="props.curNodeContent.icon"
          class="rightIcon"
        ></component>
        会员充值
      </div>
      <div class="mt1">
        <div class="textIndent">可根据自身需求充值、续费、更换会员套餐；</div>
        <div class="textIndent">
          先选择你所需要的会员套餐——在选择数量——然后选择支付方式跳转支付即可，所有会员套餐自动到账（<span style="color: red">注：子账号不支持充值</span>）；如需其他充值方式请联系客服咨询。
        </div>
        <div>
          <img :src="jc12" alt="" class="imgStyle" />
        </div>
      </div>
    </template>
    <template v-if="props.curNodeContent.id == 'zzhgl' || isMobile">
      <div class="bodyTitle alignCenter">
        <component
          :is="props.curNodeContent.icon"
          class="rightIcon"
        ></component>
        子账号管理
      </div>
      <div class="mt1">
        <div class="textIndent">
          新增、删除子账号；给子账号分配字符资源；启用、禁用子账号；修改子账号密码等相关功能。
        </div>
        <div>
          <img :src="jc13" alt="" class="imgStyle" />
        </div>
      </div>
    </template>
    <template v-if="props.curNodeContent.id == 'xgmm' || isMobile">
      <div class="bodyTitle alignCenter">
        <component
          :is="props.curNodeContent.icon"
          class="rightIcon"
        ></component>
        其他功能
      </div>
      <div class="mt1">
        <div class="textIndent">
          修改当前登录账号密码、查询当前登录账号日志等更多功能。
        </div>
        <div>
          <img :src="jc14" alt="" class="imgStyle" />
        </div>
      </div>
    </template>
    <template v-if="props.curNodeContent.id == 'tgyl' || isMobile">
      <div class="bodyTitle alignCenter">
        <component
          :is="props.curNodeContent.icon"
          class="rightIcon"
        ></component>
        推广有礼
      </div>
      <div class="mt1">
        <div class="textIndent">
          复制链接分享给朋友，注册成功并在线充值后，可获得相应的会员权限奖励。
        </div>
        <div>
          <img :src="jc15" alt="" class="imgStyle" />
        </div>
      </div>
    </template>
    <template v-if="props.curNodeContent.id == 'yhzx' || isMobile">
      <div class="bodyTitle alignCenter">
        <component
          :is="props.curNodeContent.icon"
          class="rightIcon"
        ></component>
        用户中心
      </div>
      <div class="mt1">
        <div class="textIndent">
          管理当前登录的翻译账号，切换翻译通道，管理子账户信息，切换翻译服务器线路（<span style="color: red">注：翻译线路只和翻译有关</span>），管理编辑快捷回复等功能。
        </div>
        <div>
          <img :src="jc16" alt="" class="imgStyle" />
        </div>
      </div>
    </template>
    <template v-if="props.curNodeContent.id == 'tjyy' || isMobile">
      <div class="bodyTitle alignCenter">
        <component
          :is="props.curNodeContent.icon"
          class="rightIcon"
        ></component>
        添加应用
      </div>
      <div class="mt1">
        <div class="textIndent">
          可一键添加多个应用，<span style="color: red">注：所有支持的应用都可以无限多开</span>  
        </div>
        <div>
          <img :src="jc17" alt="" class="imgStyle" />
        </div>
        <div class="mt0_5">
          <img :src="jc18" alt="" class="imgStyle" />
        </div>
        <div class="mt0_5">
          <img :src="jc19" alt="" class="imgStyle" />
        </div>
      </div>
    </template>
    <template v-if="props.curNodeContent.id == 'yygl' || isMobile">
      <div class="bodyTitle alignCenter">
        <component
          :is="props.curNodeContent.icon"
          class="rightIcon"
        ></component>
        应用管理
      </div>
      <div class="mt1">
        <div class="textIndent">
          应用列表展开模式下，点击应用下方刷新和编辑按钮，可快速刷新应用和编辑应用名称。
        </div>
        <div>
          <img :src="jc20" alt="" class="imgStyle" />
        </div>
        <div class="mt0_5">
          <img :src="jc21" alt="" class="imgStyle" />
        </div>
      </div>
    </template>
    <template v-if="props.curNodeContent.id == 'gysz' || isMobile">
      <div class="bodyTitle alignCenter">
        <component
          :is="props.curNodeContent.icon"
          class="rightIcon"
        ></component>
        翻译设置
      </div>
      <div class="mt1">
        <div class="textIndent">
          在联系人对话界面点击右侧翻译设置快捷按钮，即可选择全局或单人的翻译语言，以及翻译字体大小颜色等更多功能。
        </div>
        <div>
          <img :src="jc22" alt="" class="imgStyle" />
        </div>
      </div>
    </template>
    <template v-if="props.curNodeContent.id == 'kjhf' || isMobile">
      <div class="bodyTitle alignCenter">
        <component
          :is="props.curNodeContent.icon"
          class="rightIcon"
        ></component>
        快捷回复
      </div>
      <div class="mt1">
        <div class="textIndent">
          点击软件上面的用户中心，下拉到快捷回复管理处，点击新增快捷回复创建内容（支持文字、图片等多种格式)。<span style="color:red">注：快捷回复的编辑、新增、删除、分享给子账号等相关功能皆在此处管理。</span>
        </div>
        <div>
          <img :src="jc23" alt="" class="imgStyle" />
        </div>
      </div>
      <div class="mt1">
        <div class="textIndent">
          在联系人对话窗口点击右侧快捷回复按钮展开即可一键点击快捷发送设置好的内容。
        </div>
        <div>
          <img :src="jc24" alt="" class="imgStyle" />
        </div>
      </div>
    </template>
    <template v-if="props.curNodeContent.id == 'fsbz' || isMobile">
      <div class="bodyTitle alignCenter">
        <component
          :is="props.curNodeContent.icon"
          class="rightIcon"
        ></component>
        粉丝备注
      </div>
      <div class="mt1">
        <div class="textIndent">
          在联系人对话窗口点击右侧粉丝备注快捷操作按钮，即可进入粉丝画像界面，可查看并编辑粉丝信息。
        </div>
        <div>
          <img :src="jc25" alt="" class="imgStyle" />
        </div>
      </div>
    </template>
    <template v-if="props.curNodeContent.id == 'qfxx' || isMobile">
      <div class="bodyTitle alignCenter">
        <component
          :is="props.curNodeContent.icon"
          class="rightIcon"
        ></component>
        群发消息
      </div>
      <div class="mt1">
        <div class="textIndent">
          应用登录后的界面，点击右侧群发消息快捷操作按钮，即可对不同联系人和群组发送同一条消息。
        </div>
        <div>
          <img :src="jc26" alt="" class="imgStyle" />
        </div>
      </div>
    </template>
    <template v-if="props.curNodeContent.id == 'aihf' || isMobile">
      <div class="bodyTitle alignCenter">
        <component
          :is="props.curNodeContent.icon"
          class="rightIcon"
        ></component>
        AI回复
      </div>
      <div class="mt1">
        <div class="textIndent">
          点击右侧AI回复快捷操作按钮，进入并打开AI回复开关，在联系人对话界面点击收到消息后面的AI回复图标，即可生成AI回复内容。
        </div>
        <div>
          <img :src="jc27" alt="" class="imgStyle" />
        </div>
      </div>
    </template>
    <template v-if="props.curNodeContent.id == 'dlsz' || isMobile">
      <div class="bodyTitle alignCenter">
        <component
          :is="props.curNodeContent.icon"
          class="rightIcon"
        ></component>
        代理设置
      </div>
      <div class="mt1">
        <div class="textIndent">
          点击打开右侧代理设置快捷操作按钮，即可设置针对当前应用的独立代理IP；亦可在软件上方的设置里进行全局代理设置。
        </div>
        <div>
          <img :src="jc28" alt="" class="imgStyle" />
        </div>
        <div class="mt0_5">
          <img :src="jc29" alt="" class="imgStyle" />
        </div>
      </div>
    </template>
    <template v-if="props.curNodeContent.id == 'xtsz' || isMobile">
      <div class="bodyTitle alignCenter">
        <component
          :is="props.curNodeContent.icon"
          class="rightIcon"
        ></component>
        系统设置
      </div>
      <div class="mt1">
        <div class="textIndent">
          点击软件上方设置按钮，进入软件系统设置，可快速调整横板布局、清理缓存、更新应用及更新主软件等功能。
        </div>
        <div>
          <img :src="jc30" alt="" class="imgStyle" />
        </div>
      </div>
    </template>
    <template v-if="props.curNodeContent.id == 'znai' || isMobile">
      <div class="bodyTitle alignCenter">
        <component
          :is="props.curNodeContent.icon"
          class="rightIcon"
        ></component>
        智能AI
      </div>
      <div class="mt1">
        <div class="textIndent">
          智能问答、聊天对话、文案写手等多元化功能，懂你所言、答你说问、解你所难。
        </div>
        <div>
          <img :src="jc31" alt="" class="imgStyle" />
          <div class="mt0_5">
            <img :src="jc32" alt="" class="imgStyle" />
          </div>
        </div>
      </div>
    </template>
    <template v-if="props.curNodeContent.id == 'tpfy' || isMobile">
      <div class="bodyTitle alignCenter">
        <component
          :is="props.curNodeContent.icon"
          class="rightIcon"
        ></component>
        图片翻译
      </div>
      <div class="mt1">
        <div class="textIndent">
          可快速将本地图片或网页图片翻译成你所需要的语言。
        </div>
        <div>
          <img :src="jc33" alt="" class="imgStyle" />
        </div>
        <div class="mt0_5">
          <img :src="jc34" alt="" class="imgStyle" />
        </div>
      </div>
    </template>
    <template v-if="props.curNodeContent.id == 'khzx' || isMobile">
      <div class="bodyTitle alignCenter">
        <component
          :is="props.curNodeContent.icon"
          class="rightIcon"
        ></component>
        客户中心
      </div>
      <div class="mt1">
        <div class="textIndent">
          客户中心提供查看当前账号所登录应用的进粉数据和客户详情。
        </div>
        <div class="textIndent">
          主账号可查看子账号的粉丝数据及详情，不同账号之间亦可分享共享客户信息。
        </div>
        <div>
          <img :src="jc35" alt="" class="imgStyle" />
        </div>
        <div class="mt0_5">
          <img :src="jc36" alt="" class="imgStyle" />
        </div>
      </div>
    </template>
    <template v-if="props.curNodeContent.id == 'xssz' || isMobile">
      <div class="bodyTitle alignCenter">
        <component
          :is="props.curNodeContent.icon"
          class="rightIcon"
        ></component>
        显示设置
      </div>
      <div class="mt1">
        <div class="textIndent">
          点击软件上方的日间模式/夜晚模式，即可快速切换软件显示的外观模式。
        </div>
        <div>
          <img :src="jc37" alt="" class="imgStyle" />
        </div>
      </div>
    </template>
  </div>
</template>
<script setup>
import { onMounted, defineProps, ref } from "vue";
import jc1 from "@/assets/imgs/jc/jc1.png";
import jc2 from "@/assets/imgs/jc/jc2.png";
import jc3 from "@/assets/imgs/jc/jc3.png";
import jc4 from "@/assets/imgs/jc/jc4.png";
import jc5 from "@/assets/imgs/jc/jc5.png";
import jc6 from "@/assets/imgs/jc/jc6.png";
import jc7 from "@/assets/imgs/jc/jc7.png";
import jc8 from "@/assets/imgs/jc/jc8.png";
import jc9 from "@/assets/imgs/jc/jc9.png";
import jc10 from "@/assets/imgs/jc/jc10.png";
import jc11 from "@/assets/imgs/jc/jc11.png";
import jc12 from "@/assets/imgs/jc/jc12.png";
import jc13 from "@/assets/imgs/jc/jc13.png";
import jc14 from "@/assets/imgs/jc/jc14.png";
import jc15 from "@/assets/imgs/jc/jc15.png";
import jc16 from "@/assets/imgs/jc/jc16.png";
import jc17 from "@/assets/imgs/jc/jc17.png";
import jc18 from "@/assets/imgs/jc/jc18.png";
import jc19 from "@/assets/imgs/jc/jc19.png";
import jc20 from "@/assets/imgs/jc/jc20.png";
import jc21 from "@/assets/imgs/jc/jc21.png";
import jc22 from "@/assets/imgs/jc/jc22.png";
import jc23 from "@/assets/imgs/jc/jc23.png";
import jc24 from "@/assets/imgs/jc/jc24.png";
import jc25 from "@/assets/imgs/jc/jc25.png";
import jc26 from "@/assets/imgs/jc/jc26.png";
import jc27 from "@/assets/imgs/jc/jc27.png";
import jc28 from "@/assets/imgs/jc/jc28.png";
import jc29 from "@/assets/imgs/jc/jc29.png";
import jc30 from "@/assets/imgs/jc/jc30.png";
import jc31 from "@/assets/imgs/jc/jc31.png";
import jc32 from "@/assets/imgs/jc/jc32.png";
import jc33 from "@/assets/imgs/jc/jc33.png";
import jc34 from "@/assets/imgs/jc/jc34.png";
import jc35 from "@/assets/imgs/jc/jc35.png";
import jc36 from "@/assets/imgs/jc/jc36.png";
import jc37 from "@/assets/imgs/jc/jc37.png";
const isMobile =
  window.screen.width > 768 || window.screen.width == 768 ? false : true;
const props = defineProps({
  curNodeContent: {
    type: Object,
    default: () => {
      return {};
    },
  },
});
const features = ref([
  "★支持全球主流20+款社交APP",
  "★支持deepl、谷歌、微软等多种翻译通道",
  "★支持全球200+语种互相翻译",
  "★支持单人翻译语言独立设置",
  "★支持应用无限多开",
  "★支持无限子账号管理",
  "★支持语音翻译、图片翻译",
  "★支持文本、图片快捷回复",
  "★支持群发打粉、计数器",
  "★支持AI自动回复",
  "★支持粉丝画像",
  "★支持自定义IP代理",
]);
const urls = ref([
  {
    desc: "易翻译官网",
    url: "www.traneasy.com.cn",
  },
  {
    desc: "易翻译下载",
    url: "https://www.traneasy.com.cn/#/download",
  },
  {
    desc: "易翻译注册",
    url: "https://www.traneasy.com.cn/#/login?type=2",
  },
  {
    desc: "易翻译登录",
    url: "https://www.traneasy.com.cn/#/login?type=1",
  },
  {
    desc: "易翻译客服验证",
    url: "https://www.traneasy.com.cn/#/contactUs",
  },
]);
onMounted(() => {});
</script>
<style scoped>
.alignCenter {
  display: flex;
  align-items: center;
}
.bodyTitle {
  font-size: 2rem;
  line-height: 2.2rem;
  font-weight: 600;
}
.rightIcon {
  height: 2rem;
  width: 2rem;
  margin-right: 0.6rem;
}
.bodyDesc {
  margin: 0.6rem 0;
  color: #676767;
}
.title1 {
  margin-top: 2rem;
  font-size: 1.75rem;
  font-weight: bold;
}
.textIndent {
  text-indent: 2rem;
}
.imgStyle {
  width: 100%;
  height: auto;
  margin-top: 1rem;
}
.mt2 {
  margin-top: 2rem;
}
.mt1 {
  margin-top: 1rem;
}

.mt0_5 {
  margin-top: 0.5rem;
}

@media (max-width: 768px) {
  .bodyTitle {
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 600;
    margin-top: 1rem;
  }
  .rightIcon {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 0.2rem;
  }
  .title1 {
    margin-top: 0.5rem;
    font-size: 1rem;
  }
  .mt2 {
    margin-top: 0.6rem;
  }
  .mt1 {
    margin-top: 0.4rem;
  }

  .mt0_5 {
    margin-top: 0.2rem;
  }
  .textIndent {
    text-indent: 1rem;
  }
}
</style>