<template>
  <Modal
    v-model="show"
    :loading="loading"
    @on-ok="submit"
    title="重置子账户密码"
  >
    <Form
      v-if="userInfo.createChildUser"
      style="padding: 0px"
      ref="form"
      :model="formData"
      :rules="rules"
      :label-width="100"
    >
      <FormItem label="子账户密码" prop="password">
        <Input
          v-model="formData.password"
          placeholder="请输入子账户密码"
          type="password"
          password
        ></Input>
      </FormItem>
      <FormItem label="确认密码" prop="password1">
        <Input
          v-model="formData.password1"
          placeholder="请再次输入密码"
          type="password"
          password
        ></Input>
      </FormItem>
    </Form>
    <div v-else class="tip-warp">
      <h3>很抱歉,您还没有开通创建子账户的权限</h3>
      <div class="tip">可选择含有指定《子账户》功能的会员组进行开通</div>
      <div style="padding: 15px 0; text-align: right">
        <Button @click="show = false" style="margin-right: 15px">关闭</Button>
        <Button type="primary" @click="toUserCenterRecharge">去开通</Button>
      </div>
    </div>
    <template #footer>
      <div style="text-align: center">
        <Button class="saveBtn" long :loading="loading" @click="submit">重置密码</Button>
      </div>
    </template>
  </Modal>
</template>

<script>
import userService from "@/service/user/index";
export default {
  name: "ChildUserPwdModal",
  emits: ["on-success"],
  data() {
    return {
      show: false,
      userId: null,
      rules: {
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            type: "string",
            min: 6,
            message: "密码至少输入6位",
            trigger: "blur",
          },
          {
            type: "string",
            max: 32,
            message: "密码最多输入32位",
            trigger: "blur",
          },
        ],
        password1: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            type: "string",
            min: 6,
            message: "密码至少输入6位",
            trigger: "blur",
          },
          {
            type: "string",
            max: 32,
            message: "密码最多输入32位",
            trigger: "blur",
          },
          {
            validator: async (rule, value, callback) => {
              if(value.trim() !== this.formData.password.trim()) {
                callback(
                  new Error("密码不一致，请确认")
                );
                return;
              }
            },
            trigger: "blur",
          },
        ],
      },
      formData: {
        password: "",
        password1: "",
      },
      loading: false,
      userInfo: {},
    };
  },
  async mounted() {
    this.userInfo = await userService.get();
  },
  methods: {
    async open(userId) {
      this.show = true;
      this.userId = userId;
      this.initForm(userId);
    },
    initForm(userId) {
      if (!userId) {
        this.initInsertForm();
      } else {
        this.initUpdateForm(userId);
      }
    },
    initInsertForm() {
      this.formData = {
        password: "",
        password1: "",
      };
    },
    async initUpdateForm() {
      this.formData = {
        password: "",
        password1: "",
      };
    },
    submit() {
      let { password } = this.formData;
      let params = { password: password.trim(), userId: this.userId };
      let sub = async () => {
        this.loading = true;
        //ajax
        let result = await userService.resetPassword(params);
        this.loading = false;
        if (!result) {
          return;
        }
        this.success();
      };
      this.$refs.form.validate((valid) => {
        if (valid) {
          sub();
        }
      });
    },
    success() {
      this.$Message.success("重置成功");
      this.$emit("on-success");
      this.show = false;
    },
    toUserCenterRecharge() {
      this.$router.push({ name: "UserCenterRecharge" });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>